/*
  import design system,
  loading should not be an issue since
  React Suspense will handle the loading part
  for every unresolve promises or fetches.
  The only downside is when the design system
  server is down.

  TODO: once design system can cater everything that
  we need, let's remove BOOTSTRAP library here
*/

@font-face {
    font-family: 'Lota Grotesque Regular';
    src: url('../assets/fonts/LotaGrotesque-Regular.woff2') format('woff2'),
         url('../assets/fonts/LotaGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lota Grotesque SemiBold';
    src: url('../assets/fonts/LotaGrotesque-SemiBold.woff2') format('woff2'),
         url('../assets/fonts/LotaGrotesque-SemiBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Override react date picker width */
.react-datepicker-wrapper {
  display: block !important;
  width: 100% !important;
}

.ds-btn.desktopTextOnly > .buttonText {
  margin-left: 8px;
}

@media (max-width: 768px) {
  .ds-btn.desktopTextOnly > .buttonText {
    display: none;
    margin-left: 0;
  }
}
