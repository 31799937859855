.reverse-spinner {
    position: relative;
    height: 60px;
    width: 60px;
    border: 4px solid transparent;
    border-top-color: #5760f0;
    border-left-color: #5760f0;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-left-color: #ffffff;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
  
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  -webkit-@keyframes spin {
    -webkit-from {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    -webkit-to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spinBack {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-720deg);
      transform: rotate(-720deg);
    }
  }
  
  @keyframes spinBack {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-720deg);
      transform: rotate(-720deg);
    }
  }

  @-webkit-keyframes bezierSpinBack {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-720deg);
      transform: rotate(-720deg);
    }
  }
  
  @keyframes bezierSpinBack {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-720deg);
      transform: rotate(-720deg);
    }
  }

.bezier-spinner-container {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.bezier-spinner-forward {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 3px solid transparent;
  border-top-color: #5760f0;
  border-left-color: #5760f0;
  border-radius: 50%;
  -webkit-animation: spin 1.2s cubic-bezier(0.4, 0.65, 0.9, 0.4) infinite;
  animation: spin 1.2s cubic-bezier(0.4, 0.65, 0.9, 0.4) infinite;
}

.bezier-spinner-backward {
  position: absolute;
  height: 55%;
  width: 55%;
  border: 3px solid transparent;
  border-bottom-color: #565EF0;
  border-right-color: #565EF0;
  border-radius: 50%;
  -webkit-animation: bezierSpinBack 1.2s cubic-bezier(0.4, 0.65, 0.9, 0.4) infinite;
  animation: bezierSpinBack 1.2s cubic-bezier(0.4, 0.65, 0.9, 0.4) infinite;
}
