.componentSlider-enter {
  position: absolute;
  z-index: -1;
  transform: translateX(100%);
}

.componentSlider-enter-active {
  transform: translateX(0);
  transition: transform 400ms ease-in-out;
}

.componentSlider-exit {
  position: absolute;
  z-index: 2;
}
